<template>
   <b-card
	   :img-src="headerData.coverImg"
	   alt="cover photo"
	   body-class="p-0"
	   class="profile-header mb-2"
	   img-top
   >
	  <!-- profile picture -->
	  <div class="position-relative">
		 <div class="profile-img-container d-flex align-items-center">
			<div class="profile-img">
			   <b-img
				   :src="headerData.avatar"
				   alt="profile photo"
				   fluid
				   rounded
			   />
			</div>
			<!-- profile title -->
			<div class="profile-title ml-3">
			   <h2 class="text-white">
				  {{ headerData.username }}
			   </h2>
			   <p class="text-white">
				  {{ headerData.designation }}
			   </p>
			</div>
			<!--/ profile title -->
		 </div>
	  </div>
	  <!--/ profile picture -->

	  <!-- profile navbar -->
	  <div class="profile-header-nav">
		 <b-navbar
			 toggleable="md"
			 type="light"
		 >
			<!-- toggle button -->
			<b-navbar-toggle
				class="ml-auto"
				target="nav-text-collapse"
			>
			   <feather-icon
				   icon="AlignJustifyIcon"
				   size="21"
			   />
			</b-navbar-toggle>
			<!--/ toggle button -->

			<!-- collapse -->
			<b-collapse
				id="nav-text-collapse"
				is-nav
			>
			   <b-tabs
				   class="profile-tabs mt-1 mt-md-0"
				   nav-class="mb-0"
				   pills
			   >
				  <template #tabs-start>
					 <b-nav-item
						 active
						 class="font-weight-bold"
						 role="presentation"
					 >
						<span class="d-none d-md-block">Feed</span>
						<feather-icon
							class="d-block d-md-none"
							icon="RssIcon"
						/>
					 </b-nav-item>
					 <b-nav-item
						 class="font-weight-bold"
						 role="presentation"
					 >
						<span class="d-none d-md-block">About</span>
						<feather-icon
							class="d-block d-md-none"
							icon="InfoIcon"
						/>
					 </b-nav-item>
					 <b-nav-item
						 class="font-weight-bold"
						 role="presentation"
					 >
						<span class="d-none d-md-block">Photos</span>
						<feather-icon
							class="d-block d-md-none"
							icon="ImageIcon"
						/>
					 </b-nav-item>
					 <b-nav-item
						 class="font-weight-bold"
						 role="presentation"
					 >
						<span class="d-none d-md-block">Friends</span>
						<feather-icon
							class="d-block d-md-none"
							icon="UsersIcon"
						/>
					 </b-nav-item>
				  </template>

				  <!-- edit buttons -->
				  <template #tabs-end>
					 <b-button
						 class="ml-auto"
						 variant="primary"
					 >
						<feather-icon
							class="d-block d-md-none"
							icon="EditIcon"
						/>
						<span class="font-weight-bold d-none d-md-block">Edit</span>
					 </b-button>
				  </template>
				  <!-- edit buttons -->
			   </b-tabs>

			</b-collapse>
			<!--/ collapse -->
		 </b-navbar>
	  </div>
	  <!--/ profile navbar -->
   </b-card>
</template>

<script>
import {BButton, BCard, BCollapse, BImg, BNavbar, BNavbarToggle, BNavItem, BTabs,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
   components: {
	  BCard,
	  BTabs,
	  BButton,
	  BNavItem,
	  BNavbar,
	  BNavbarToggle,
	  BCollapse,
	  BImg,
   },
   directives: {
	  Ripple,
   },
   props: {
	  headerData: {
		 type: Object,
		 default: () => {
		 },
	  },
   },
}
</script>
