<template>
   <b-card>
	  <h5>Twitter Feeds</h5>

	  <!-- twitter feed -->
	  <div
		  v-for="(data,index) in twitterFeed"
		  :key="data.avatar"
		  :class="index != 0 ? 'mt-2' : 'mt-1'"
		  class="profile-twitter-feed"
	  >
		 <div class="d-flex justify-content-start align-items-center mb-1">
			<b-avatar
				:src="data.imgUrl"
				class="mr-1"
				size="40"
			/>
			<div class="profile-user-info">
			   <h6 class="mb-0">
				  {{ data.title }}
			   </h6>
			   <b-link>
				  <small class="text-muted">@{{ data.id }}</small>
				  <feather-icon icon="CheckCircleIcon" />
			   </b-link>
			</div>
			<div class="profile-star ml-auto">
			   <feather-icon
				   :class="data.favorite ? 'profile-favorite':''"
				   icon="StarIcon"
				   size="18"
			   />
			</div>
		 </div>
		 <b-card-text class="mb-50">
			{{ data.desc }}
		 </b-card-text>
		 <b-link>
			<small>
			   {{ data.tags }}
			</small>
		 </b-link>
	  </div>
	  <!-- twitter feed -->
   </b-card>
</template>

<script>
import {BAvatar, BCard, BCardText, BLink,} from 'bootstrap-vue'

export default {
   components: {
	  BCard, BAvatar, BLink, BCardText,
   },
   props: {
	  twitterFeed: {
		 type: Array,
		 default: () => {
		 },
	  },
   },
}
</script>
