<template>
   <b-card>
	  <h5 class="mb-0">
		 Latest Photos
	  </h5>
	  <b-row>
		 <!-- latest photo loop -->
		 <b-col
			 v-for="data in latestImages"
			 :key="data.img"
			 class="profile-latest-img"
			 cols="6"
			 md="4"
		 >
			<b-link>
			   <b-img
				   :alt="data.img.slice(5)"
				   :src="data.img"
				   fluid
				   rounded
			   />
			</b-link>
		 </b-col>
		 <!-- latest photo loop -->
	  </b-row>
   </b-card>
</template>

<script>
import {BCard, BCol, BImg, BLink, BRow,} from 'bootstrap-vue'

export default {
   components: {
	  BCard,
	  BRow,
	  BCol,
	  BLink,
	  BImg,
   },
   props: {
	  latestImages: {
		 type: Array,
		 default: () => [],
	  },
   },
}
</script>
